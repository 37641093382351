import { Component, OnInit } from '@angular/core';
import { LoaderService } from 'src/app/core/services/loader.service';
import { ThemeService } from 'src/app/core/services/theme.service';
import { SessionService } from 'src/app/core/services/session.service';
import { TranslateService } from '@ngx-translate/core';
import { RouteStateService } from './core/services/route-state.service';

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyC5UMT6ERR4XXdcA_ERmW_4ATC7nb6bTio",
  authDomain: "villa-las-piedras.firebaseapp.com",
  projectId: "villa-las-piedras",
  storageBucket: "villa-las-piedras.appspot.com",
  messagingSenderId: "54771170069",
  appId: "1:54771170069:web:b5d5397464549ca35cc71c",
  measurementId: "G-XG0SK1MJL0"
};

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.css']
})
export class AppComponent implements OnInit {
  title = 'Ng-Prime';
  showLoader: boolean;
  theme: string;
  cajacookies;

  constructor(private loaderService: LoaderService,
    private themeService: ThemeService,
    private routeStateService:RouteStateService,
    private sessionService: SessionService,
    translate: TranslateService) {

    var theme = this.sessionService.getItem("selected-theme");
    if (theme != null && theme.length > 0) {
      this.theme = theme;
      this.themeService.selectTheme(theme);
    } else {
      this.theme = "theme-teal";
    }

    // this language will be used as a fallback when a translation isn't found in the current language
    translate.setDefaultLang('es');
    var language = this.sessionService.getItem("ng-prime-language");
    if (language != null && language.length > 0) {
      // the lang to use, if the lang isn't available, it will use the current loader to get them
      translate.use(language);
    } else {
      this.sessionService.setItem("ng-prime-language", "es");
    }
  }

  ngOnInit() {
    document.getElementsByTagName("body")[0].style.width="100%";
        this.cajacookies= <HTMLElement> document.getElementById("cajacookies");

    this.loaderService.status.subscribe((val: boolean) => {
      this.showLoader = val;
    });

    this.themeService.theme.subscribe((val: string) => {
      this.theme = val;
    });

      if(localStorage.aceptaCookies == 'true'){
        this.cajacookies.style.display = 'none';
      }
  }

   aceptarCookies() {
    localStorage.aceptaCookies = 'true';
    this.cajacookies.style.display = 'none';
  }
  
  validarRuta(){
    if(window.location.pathname==='/home' || window.location.pathname==='/doblesuperior' || window.location.pathname==='/dobledeluxe' || window.location.pathname==='/cancelarsuscripcion' ){
      document.getElementsByTagName("body")[0].style.width="100%";
      return true;
    }else{
      return false;
    }
  }
  ngOnDestroy() {
    this.themeService.theme.observers.forEach(function (element) { element.complete(); });
    this.loaderService.status.observers.forEach(function (element) { element.complete(); });
  }


}
