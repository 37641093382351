// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  apiUrl: 'https://sysgestion.somee.com/api/Gestor',
  version: '1.0.0',
  firebase: {
    apiKey: "AIzaSyBCW-ISq9kBlXPKqP0-ilgxKEvZ-3sLZdY",
    authDomain: "complejo-valle-las-piedras.firebaseapp.com",
    projectId: "complejo-valle-las-piedras",
    storageBucket: "complejo-valle-las-piedras.appspot.com",
    messagingSenderId: "432133655851",
    appId: "1:432133655851:web:7349c29db4915b2c1a896e",
    measurementId: "G-CH04EECX9H"

    
  }

};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
