import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { CustomMenuItem } from '../models/menu-item.model';

@Injectable({
    providedIn: 'root',
})
/**
 * menu data service
 */
export class MenuDataService {

    public toggleMenuBar: BehaviorSubject<any> = new BehaviorSubject<any>(null);

    getMenuList(): CustomMenuItem[] {
        return [
            {
                Label: 'Reservas', Icon: 'fa-calendar', RouterLink: '/main/reservas', Childs: null, IsChildVisible: false
            },
            {
                Label: 'Habitaciones', Icon: 'fa-bed', RouterLink: '/main/habitaciones', Childs: null, IsChildVisible: false
            },
            {
                Label: 'Tipo de habitaciones', Icon: 'fa-bed', RouterLink: '/main/tipohabitaciones', Childs: null, IsChildVisible: false
            },
            {
                Label: 'Clientes', Icon: 'fa-address-card', RouterLink: '/main/clientes', Childs: null, IsChildVisible: false
            },
            {
                Label: 'Pedidos', Icon: 'fa-book-open', RouterLink: '/main/pedidos', Childs: null, IsChildVisible: false
            },
            {
                Label: 'Productos', Icon: 'fa-shopping-bag', RouterLink: '/main/productos', Childs: null, IsChildVisible: false
            },
       
        
            {
                Label: 'Contacto', Icon: 'fa-envelope', RouterLink: '/main/contactus', Childs: null, IsChildVisible: false
            },
     
        ];
    }
}