

  <!--NavBar -->
<nav class=" animate__animated animate__backInRight  navbar fixed-top navbar-light " style="max-width: 1050px;width:device-width;margin:0 auto; background-color: #f7f7f7;">
  
  <span style ="font-size: 20px;font-family: 'Lato'; align-items: center; ">
    <div class="btn-group">
      <button id="boton" type="button" class="botonBarras" data-bs-toggle="dropdown" aria-expanded="false">
        <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" class="bi bi-list" viewBox="0 0 16 16">
          <path fill-rule="evenodd" d="M2.5 11.5A.5.5 0 0 1 3 11h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4A.5.5 0 0 1 3 7h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4A.5.5 0 0 1 3 3h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z"/>
        </svg>
            </button>


      <ul class="dropdown-menu animate__animated animate__slideInLeft animate__faster" style="width: 230px; height: 1800px;top:-10px;background-color: #f7f7f7;">

        <li>&nbsp;<i style="font-size:25px;position:absolute;left: 10px; top:15px;" class="fas fa-times"></i></li>
        <li><br></li>
        <li><br></li>
        <li><a class="dropdown-item" href="home#"><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-house-door" viewBox="0 0 16 16">
          <path d="M8.354 1.146a.5.5 0 0 0-.708 0l-6 6A.5.5 0 0 0 1.5 7.5v7a.5.5 0 0 0 .5.5h4.5a.5.5 0 0 0 .5-.5v-4h2v4a.5.5 0 0 0 .5.5H14a.5.5 0 0 0 .5-.5v-7a.5.5 0 0 0-.146-.354L13 5.793V2.5a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5v1.293L8.354 1.146zM2.5 14V7.707l5.5-5.5 5.5 5.5V14H10v-4a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5v4H2.5z"/>
        </svg>Inicio</a></li>


        <li><a class="dropdown-item" href="home#habitaciones"><img src="./assets/images/botonHabitaciones.png" width="20"/>Habitaciones</a></li>
        <li><a class="dropdown-item" href="home#reservar"><i class="far fa-calendar-alt" style="font-size: 20;"></i>&nbsp;Reservar</a></li>


        <li><a class="dropdown-item" (click)="abrirDeluxe()">Habitación Doble Deluxe</a></li>
        <li><a class="dropdown-item" (click)="abrirSuperior()">Habitación Doble Superior</a></li>
        <li><a class="dropdown-item" href="home#video">Video</a></li>
        <li><a class="dropdown-item" href="home#areas">Areas Comunes</a></li>
        <li><a class="dropdown-item" href="home#servicios">Servicios</a></li>

        <li><a class="dropdown-item" href="home#contacto"><img src="./assets/images/botonContacto.png" width="20"/>Contacto</a></li>
        <li><a class="dropdown-item" href="home#ubicacion"><img src="./assets/images/botonMapa.png" width="20"/>Ubicación</a></li>


      </ul>
    </div>


    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    <img (click)="abrirInicio()" src="../../../assets/images/banner.png" width="100%" alt="Complejo Valle Las Piedras" style="max-width: 41%;margin-left: auto; 
    margin-right: auto;">
    <a style="color:black" id="modalLogin" (click)="abrirHabitaciones()"  href="home#habitaciones" data-bs-toggle="modal" data-bs-target="#staticBackdrop"><i style="position:absolute;right: 10px; top:23px;" class="fas fa-user animate__animated animate__flip animate__delay-1s"></i> </a>
  </span>

  </nav>

  
  
<br>
<br>
<br>
