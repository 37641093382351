<div class="ng-header">
    <div class="ng-header-left left">
        <div class="toggle-menu-button left">
            <a class="icon" (click)="toggleMenu()"><i class="fa fa-bars"></i></a>
        </div>
 
    </div>
    <div class="ng-header-right right">
        <div class="ng-header-right-item right">
            <a class="icon" (click)="logout()"><i class="fa fa-sign-out-alt"></i></a>
        </div>
        <div class="ng-header-right-item right" (click)="themeChooser.toggle($event)">
            <a class="icon"><i class="fa fa-cog"></i></a>
        </div>
        <div class="ng-header-right-item right" (click)="showNotificationSidebar()">
            <a class="icon"><i class="fa fa-bell"></i><span class="badge"></span></a>
        </div>
        <div class="ng-header-right-item right" (click)="userdetails.toggle($event)">
        </div>
    </div>
</div>

<!-- user detials overlay panel -->
<p-overlayPanel #userdetails>
    <div><b>User Name: </b><i [innerHtml]="user.nombre"></i></div>

</p-overlayPanel>

<!-- notifications sidebar -->
<p-sidebar [(visible)]="displayNotifications" position="right">
    <div *ngFor="let notification of notifications; let i=index" class="notification-message">
        <div><b>Message: </b><i [innerHtml]="notification.message"></i></div>
        <div><i>Created On: </i><i [innerHtml]="notification.createdOn | date:'dd/MM/yyyy'"></i></div>
    </div>
</p-sidebar>

<!-- theme chooser overlay panel -->
<p-overlayPanel #themeChooser>
    Select Theme:
    <div class="ui-g">
        <div class="ui-g-3">
            <button pButton type="button" (click)="selectTheme('theme-teal')"
                style="background-color: #427976;"></button>
        </div>
        <div class="ui-g-3">
            <button pButton type="button" (click)="selectTheme('theme-rose')"
                style="background-color: #79425a;"></button>
        </div>
        <div class="ui-g-3">
            <button pButton type="button" (click)="selectTheme('theme-light')"
                style="background-color: #cccccc"></button>
        </div>
        <div class="ui-g-3">
            <button pButton type="button" (click)="selectTheme('theme-dark')"
                style="background-color: #3b3b48"></button>
        </div>
        <div class="ui-g-3">
            <button pButton type="button" (click)="selectTheme('theme-Turquoise')"
                style="background-color:#04838f;"></button>
        </div>
        <div class="ui-g-3">
            <button pButton type="button" (click)="selectTheme('theme-green')"
                style="background-color: #1e8455;"></button>
        </div>
        <div class="ui-g-3">
            <button pButton type="button" (click)="selectTheme('theme-blue')"
                style="background-color: #2d5394;"></button>
        </div>
    </div>
</p-overlayPanel>