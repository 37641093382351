<meta name="viewport" content="width=device-width, initial-scale=1">


<div class="ui-fluid ng-main" [ngClass]="theme">
    <div *ngIf="validarRuta()">
    <app-navbar></app-navbar>
</div>
    <div class="alert alert-warning alert-dismissible fade show" id="cajacookies">
        <p><button type="button" class="btn btn-secondary" (click)="aceptarCookies()" ><i class="fa fa-times"></i> Aceptar y cerrar éste mensaje</button>
        Éste sitio web usa cookies, si permanece aquí acepta su uso.
        </p>
        </div>


    <router-outlet></router-outlet>
</div>

