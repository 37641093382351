import { Component } from '@angular/core';
import { RouteStateService } from 'src/app/core/services/route-state.service';


@Component({
  selector: 'app-navbar',
  templateUrl: 'navbar.component.html',
  styleUrls: ['navbar.component.css']
})

export class NavbarComponent {


constructor(

  private routeStateService: RouteStateService

) {}


ngAfterViewInit():void{

}

ngOnInit(): void {
  



}



abrirSuperior(){
  this.routeStateService.add("DobleSuperior", '/doblesuperior', null, true);

}

abrirInicio(){
  window.location.href="home#";
}

abrirHabitaciones(){
  let modal = document.getElementById('staticBackdrop');
  if(modal==null){
    window.location.href="home#habitaciones";
    setTimeout(() => {let boton= document.getElementById('boton').click();}, 1000);
    setTimeout(() => {let modal= document.getElementById('modalLogin').click();}, 1001);
  }
}
abrirDeluxe(){
  this.routeStateService.add("DobleDeluxe", '/dobledeluxe', null, true);

}

}